// extracted by mini-css-extract-plugin
export var Btn = "r_bl";
export var inner = "r_m";
export var content = "r_r";
export var contentHover = "r_bm";
export var arrow = "r_bn";
export var leftArrow = "r_bp";
export var disabledArrow = "r_bq";
export var text = "r_br";
export var textHover = "r_bs";
export var outline = "r_bt";
export var bg = "r_bv";
export var bgHover = "r_bw";
export var isDisabled = "r_bx";
export var aniSpin = "r_d";